floatingMenu();
$(window).scroll(function() {
    floatingMenu();
});

innerHeight = window.innerHeight;

function floatingMenu(){
        if($(window).scrollTop() >= innerHeight - 100 ) {
            if($("header.floating").css("display") == 'none'){
                $("header.floating").fadeIn("slow");
            }

        }else{
                $("header.floating").fadeOut();
        }
    topMenu = $("header nav.navbar");
    topMenuHeight = topMenu.outerHeight()+20;
}


  var lastId,
  topMenu = $("header nav.navbar"),
  topMenuHeight = topMenu.outerHeight()+20,
  menuItems = topMenu.find("a"),
  scrollItems = menuItems.map(function(){
    var item = $($(this).attr("href"));
    if (item.length) { return item; }
  });

  menuItems.click(function(e){
    var href = $(this).attr("href"),
        offsetTop = href === "#" ? 0 : $(href).offset().top-topMenuHeight+1;
    $('html, body').stop().animate({
        scrollTop: offsetTop
    }, 800);
    e.preventDefault();
});

  $("#carousel-link").click(function(e){
    var href = $(this).attr("href"),
        offsetTop = href === "#" ? 0 : $(href).offset().top-topMenuHeight-35;
    $('html, body').stop().animate({
        scrollTop: offsetTop
    }, 800);
    e.preventDefault();
});

$('#carousel .slider').slick({
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
});

$('#customers .gallery1').slick({
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    swipe: false,
    autoplaySpeed: 2000,
    asNavFor: '#customers .gallery2, #customers .gallery3',
    responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
});

$('#customers .gallery2').slick({
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    swipe: false,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
});

$('#customers .gallery3').slick({
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    swipe: false,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
});

$("#customers .arrow-left").click(function(){
    $("#customers .gallery1").slick('slickPrev');
});

$("#customers .arrow-right").click(function(){
    $("#customers .gallery1").slick('slickNext');
});

$(window).scroll(function(){

   var fromTop = $(this).scrollTop()+topMenuHeight;


   var cur = scrollItems.map(function(){
     if ($(this).offset().top < fromTop)
       return this;
   });

   cur = cur[cur.length-1];
   var id = cur && cur.length ? cur[0].id : "";

   if (lastId !== id) {
       lastId = id;

       menuItems
         .parent().removeClass("active")
         .end().filter("[href=#"+id+"]").parent().addClass("active");
   }
});

$(".more-info-toggle").click(function(){
    $(".more-info").slideToggle();
});

function openGallery(category, project){
  gallery = $(this).lightGallery({
    download: false,
    dynamic: true,
    share: false,
    dynamicEl: category == '3d' ? finalThirdGallery[project] : finalGallery[project]
  });
  gallery.on('onBeforeClose.lg',function(event, index, fromTouch, fromThumb){
    gallery.data('lightGallery').destroy(true);
  });
}

$(document).ready(function() {

  if (typeof generalGallery !== 'undefined') {

    finalGallery = [];
    $.each(generalGallery, function(i, project) {
      finalGallery[i] = [];
      $.each(project, function(j, galleryItem) {
        finalGallery[i].push({
          "src": baseUrl + "/" + galleryItem.path,
          "thumb": baseUrl + "/" + galleryItem.path,
          "subHtml": "Guara Container"
        });
      });
    })
  }

  if (typeof thirdGallery !== 'undefined') {

    finalThirdGallery = [];
    $.each(thirdGallery, function(i, project) {
      finalThirdGallery[i] = [];
      $.each(project, function(j, galleryItem) {
        finalThirdGallery[i].push({
          "src": baseUrl + "/" + galleryItem.path,
          "thumb": baseUrl + "/" + galleryItem.path,
          "subHtml": "Projetos 3D"
        });
      });
    })
  }

  $(".project .gallery").lightGallery({
    download: false,
    share: false,
    thumbnail: true,
    exThumbImage: 'data-exthumbimage',
  });

  // function loadPhotos(category, qty){

  //   loadAllowed = 0;

  //   if(category == "general" && generalFull == 0){
  //     loadAllowed = 1;
  //   }else if(category == "3d" && thirdFull == 0){
  //     loadAllowed = 1;
  //   }else if(category == "2d" && secondFull == 0){
  //     loadAllowed = 1;
  //   }

  //   if(loadAllowed){
  //     $("#"+ category +"-loader").css("display", "block");
  //     $.getJSON(
  //         "photos/category/"+ category +"/page/" + photoPage + "/qty/" + qty,
  //         function(data){
  //             photos = data.data;
  //             item = $("<div class='slider-item'></div>");
  //               $.each(photos, function(i, photo){
  //                 if(category == "general"){
  //                   item.append("<div class='col-xs-6 col-sm-4 col-md-3 gallery-item'><a href='"+ photo.path +"' class='thumbnail fancybox'rel='"+ category +"' title='"+ photo.name +"' style='background: url("+ photo.path +")'></a></div>");
  //                 }else{
  //                   item.append("<div class='col-xs-6 col-sm-4 gallery-item'><a href='"+ photo.path +"' class='thumbnail fancybox'rel='"+ category +"' title='"+ photo.name +"' style='background: url("+ photo.path +")'></a></div>");
  //                 }
  //               });

  //           if(photos.length != 0){
  //             photoPage++;
  //             photoCount++;
  //             $("#" + category + " .gallery").slick("slickAdd", item);
  //           }else{
  //             if(category == "general"){
  //               generalFull = 1;
  //             }else if(category == "3d"){
  //               thirdFull = 1;
  //             }else if(category == "2d"){
  //               secondFull = 1;
  //             }
  //           }
  //           $("#"+ category +"-loader").css("display", "none");
  //           $("#" + category + " .gallery").slick('slickNext');
  //         }
  //     );
  //   }else{
  //     $("#" + category + " .gallery").slick('slickNext');
  //   }

  // }

  $('#projects .gallery').slick({
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      adaptiveHeight: true
      // autoplay: true,
      // autoplaySpeed: 4000
  });
    $('#3d .gallery').slick({
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false
      // autoplay: true,
      // autoplaySpeed: 4000
  });
  $('#2d .gallery').slick({
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false
      // autoplay: true,
      // autoplaySpeed: 4000
  });


  // $('#projects .arrow-right').click(function(){
  //   loadPhotos("general", 12);
  // });

  // $('#3d .arrow-right').click(function(){
  //   loadPhotos("3d", 6);
  // });

  // $('#2d .arrow-right').click(function(){
  //   loadPhotos("2d", 6);
  // });

  // $('.fancybox').fancybox({
  //     arrows : false,
  //     nextClick : true,
  //     helpers : {
  //       media : {},
  //       thumbs : {
  //         width  : 50,
  //         height : 50
  //       }
  //     }
  //   });

    $('form').submit(function(event) {

        $("#contact-result").slideUp();
        $("#contact-loader").fadeIn();

        name = $('input[name=name]').val();
        email = $('input[name=email]').val();
        message = $('textarea[name=message]').val();
        token = $("input[name=_token]").val();

        // process the form
        $.ajax({
            headers: {'X-CSRF-TOKEN': token},
            type        : 'POST', // define the type of HTTP verb we want to use (POST for our form)
            url         : baseUrl + 'contact', // the url where we want to POST
            data        : {_token: token, name: name, email: email, message: message},
            dataType    : 'json', // what type of data do we expect back from the server
            encode          : true,
            success : function(data){
              $("#contact-loader").slideUp();
              $("#contact-result").text(data.contact.message)
              if(data.contact.result == "1"){
                $("#contact-result").removeClass("alert-danger");
                $("#contact-result").addClass("alert-success");
              }else{
                $("#contact-result").removeClass("alert-success");
                $("#contact-result").addClass("alert-danger");
              }
              $('#contact form').trigger("reset");
              $("#contact-result").fadeIn();
            },
            error : function(){
              $("#contact-loader").slideUp();
              $("#contact-result").text("Erro ao enviar mensagem. Tente novamente mais tarde.");
              $("#contact-result").removeClass("alert-success");
              $("#contact-result").addClass("alert-danger");
              $("#contact-result").fadeIn();
            }
        })

        event.preventDefault();
    });

});
